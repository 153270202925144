import { Ax } from '@/utils';
import formsSetVariantAttrMixin from './forms-set-variant-attr-mixin';
import { variantNameLangSupport } from '@/modules/budget/budgetCorrectHelper';
//  ***************************************** //
//      Миксин для форм без расшифровок       //
//  ***************************************** //
export default {
    mixins: [formsSetVariantAttrMixin],
    data() {
        return {
            openDisabled: false,
            budgetForm: [],
            header: null,
            load: false,
            isLoad: false,
            variantName: null,
            dataTypeFilter: null,
            formFilter: null,           
            progress: 0,
            isReportUploading: false,
            selectAll: false,
            userHasSeveralGus: false,
            guListLen: 0,
            formsList: ['133', '154', '155', '156', '157', '158', '163', '212', '213', '311', 
            '321', '322', '331', '332', '338', '352', '411', '412', '417', '418', '419', 
            '421', '423', '511', '513', '514', '612', '711', '712', '812', '813', '814', 
            '815', '02-322', '01-339', '02-339'], 
            noSigners: [],
            formName: null, 
            spfName: null,
            budgetFormNoPpr: [],
            budgetFormToCopy: [],
            messages: {
                addRegion: 'Добавить район',
                addProject: 'Добавить проект'
            },
            filterData: null,
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.$refs.budgetHeader.urlChanged(to);
        next()
    },

    methods: {
        async changeHeader(data) {
            try {
                this.openDisabled = true;
                const curHeader = data;
                this.filterData = curHeader;
                if ((curHeader !== null)
                    && (curHeader.dataType !== null)
                    && (curHeader.gr !== null)
                    && (curHeader.gu !== null)
                    && (curHeader.prg !== null)) {
                    this.header = {
                        form: curHeader.form.code,
                        year: curHeader.yearProp,
                        cur_year: curHeader.year,
                        data_type: curHeader.dataType.code,
                        gu: curHeader.gu.code,
                        id_region: curHeader.gu.id_region,
                        gr: curHeader.gr.gr,
                        abp: curHeader.abp.abp,
                        prg: curHeader.prg.prg,
                        ppr: curHeader.ppr,
                        spf: curHeader.spf.spf,
                        region_code: curHeader.region,
                        variant: curHeader.budgetVersion.variant_uuid,
                        variant_date_time: curHeader.budgetVersion.date_start,
                        user_name: this.$store.state.user.sub,
                        mode: curHeader.mode,
                        gkkp: curHeader.gkkp,
                    };
                    this.formName = curHeader.form.name;
                    this.spfName = curHeader.spf.name;
                    let filteredGuList = curHeader.guList;
                    if (curHeader.region.slice(-4) !== '0101') {
                        filteredGuList = curHeader.guList.filter(itm => itm.id_region === curHeader.region)
                    }
                    this.guListLen = filteredGuList ? filteredGuList.length : 0;
                    if (curHeader.gu.budget_type === '02') {
                        this.header.id_region = curHeader.region;
                    }
                    this.dataTypeFilter = curHeader.dataType.name;
                    this.formFilter = this.form.code + ' - ' + this.form.name_ru;

                    // если атрибут поля attribute в budget_variants имеет значение true - редактирование формы возможно,
                    // если значение false/null - редактирование запрещено
                    this.setVariantAttribute(curHeader.budgetVersion.attribute, this.header, '004.002.004')
                    // this.variantAttribute = curHeader.budgetVersion.attribute;
                    
                    // console.log('НЕ ЗАБУДЬ УДАЛИТЬ')
                    // setTimeout(() => this.variantAttribute = true, 1000)
                    this.variantName = curHeader.budgetVersion.name_ru;
                    this.progress = 30;
                    await this.loadSpecificData();
                    
                    // this.progress = 60;
                    const timeout1 = setTimeout(() => this.progress = 50, 100)
                    const timeout2 = setTimeout(() => this.progress = 70, 100)
                    const timeout3 = setTimeout(() => this.progress = 80, 100)
                    await this.loadDatas();
                    clearTimeout(timeout1);
                    clearTimeout(timeout2);
                    clearTimeout(timeout3);
                    this.progress = 100;
                } 
                // else {
                //     this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
                // }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка обновления параметров');
            } finally {
                this.openDisabled = false;
            }
        }, // обновление параметров

        async loadSpecificData() {
            // при необходимоси добавить в форме
        },

        async checkSignatories(batch = false, oneGu = false) {
            this.header.code_modules = this.moduleCode;
            this.header.batch = batch;
            this.header.oneGu = oneGu;
            try {
                const response = await fetch('/api-py/check-signatories/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.header)
                });
                if (response.status === 200) {
                    const result = await response.json();
                    if (result > 0) {
                        this.showSignersModal(result)
                        // this.$refs.modalSignatories.showModal();
                    }
                } else {
                    this.makeToast('danger', 'Ошибка проверки наличия подписантов', `${response.status} error`);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка проверки наличия подписантов', error.toString());
            }
        }, // проверка наличия подписантов перед скачиванием

        showSignersModal(forms) {

            this.$bvModal.msgBoxOk('Отсутствуют Подписант(ы) по формам расчета', {
              size: 'lg',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'Закрыть',
              headerClass: 'p-2',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            })
              .then(value => {
              })
              .catch(err => {
                // An error occurred
              })
          },
        
        downloadRep() {
            this.isReportUploading = true;
            this.header.lang = this.$i18n.locale;
            this.header.code_modules = this.moduleCode;
            Ax(
                {
                    url: '/api-py/budg_' + this.form.code.replace('-', '_') + '/' + JSON.stringify(this.header),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Форма ' + this.form.code.replace('-', '_') + '.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.isReportUploading = false;
                },
                (error) => {
                    this.isReportUploading = false;
                    this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                }
            );
        },

        downloadBatchReports(oneGu) {
            this.isReportUploading = true;
            this.$store.commit('setIsDownloadButtonEnabled');
            this.makeToast('info', 'Внимание', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
            this.header.lang = this.$i18n.locale;
            this.header.oneGu = oneGu === true;
            this.header.code_modules = this.moduleCode;
            Ax(
                {
                    url: '/api-py/budg_batch_reports/' + JSON.stringify(this.header),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    if (data && data.size > 0) {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `Пакетная выгрузка ${oneGu === true ? 'ГУ' : 'АБП'}.xls`);// or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.$store.commit('setIsDownloadButtonEnabled');
                    } else {
                        throw new Error('Error');
                    }
                    this.isReportUploading = false;
                },
                (error) => {
                    this.isReportUploading = false;
                    this.makeToast('danger', 'Ошибка запроса downloadBatchReports()', error.toString());
                    this.$store.commit('setIsDownloadButtonEnabled');
                }
            );
        },

        keyPress(event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13(event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {

            this.load = true;
            try {
                const response = await fetch('/api-py/get-budget-request-form-datas/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.header)
                });
                if (response.status === 200) {
                    const values = await response.json();
                    this.budgetForm.splice(0);
                    if (values && values.length > 0) {
                        values.forEach(val => {
                            this.setDatasParams(val)
                        });
                    }
                    else {
                        this.addItem();
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDatas', error.toString());
            }
            this.load = false;
        }, 

        setDatasParams(val) {
            // заменить в родительском компоненте
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        async save(values) {
            this.isLoad = true;
            try {
                const response = await fetch('/api-py/save-brform' + this.form.code + '/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                
                if (response.status === 200) {
                    if (this.files.length === 0) {
                        this.makeToast('danger', 'Предупреждение', 'Внимание! Отсутствуют необходимые документы. Пожалуйста, прикрепите недостающие файлы.');
                    }
                    this.makeToast('success', 'Сообщение', 'Данные сохранены');
                    this.selectAll = false;
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw 'Ошибка сохранения данных. Возможно не все обязательные поля заполнены';
                }
            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        inputFixed(item, field, value, digit, defaultVal) {
            if (value === null || value === '' || value === '.' || !Number(value)) {
                this.inputFixedVldtn(item, field, value, digit, defaultVal);
                return;
            };
            if (field === 'number_months') {
                this.monthValidation(value, item, field, digit);
                return;
            };
            if (field === 'amount_days') {
                this.yearValidation(value, item, field);
                return;
            };
            this.inputFixedVldtn(item, field, value, digit);
            // this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
        }, // форматирует введенное значение до digit цифр после запятой
        
        inputFixedVldtn(item, field, value, digit, defaultVal = 0) {
            // nextTick добавлен тк не без него не работает реактивность при событии keyPress.enter если предыдущее значение = 0 
            this.$nextTick(() => {
                const newVal = parseFloat(value) ? parseFloat(parseFloat(value).toFixed(digit)) : defaultVal;
                this.$set(item, field, newVal);
            })
        }, // форматирует введенное значение до digit цифр после запятой

        deleteItem(msg = null, row = false, index = false) {
            let message = 'Подтвердите удаление данной записи...';
            if (msg) {
                message = msg;
            }
            this.$bvModal.msgBoxConfirm(
                message,
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'ДА',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (row) {
                            this.prepareForDelete(row, index);
                            if (row.id > 0) {
                                this.delete([row]);
                            }
                        } else {
                            this.prepareForDelete(row, index);
                        }
                        
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        },
        
        prepareForDelete(row, index) {
            // при необходимости заменить в ко
        },

        getFormCode() {
            // при необходимости заменить в родительском компоненте. Напр. Form01_142.vue
            return this.form.code;
        },
        
        deleteItemWithAttachedFiles(msg, row = false, index = false) {
            if (!this.variantAttribute || this.isLoad 
                || (!row && this.budgetForm.findIndex(itm => itm.itemToDelete) === -1)) return;
            this.$bvModal.msgBoxConfirm(
                msg,
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'ДА',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    if (value) {
                        if (row) {
                            if (row.id > 0) {
                                await this.deleteWithAttachedFiles([row], [row.id], index);
                            } else {
                                this.budgetForm.splice(index, 1);
                            }
                        } else {
                            const rows = [];
                            const rowsId = [];
                            const newRowsToDelId = [];
                            this.budgetForm.forEach(item => {
                                if (item.itemToDelete) {
                                    if (item.id > 0) {
                                        rows.push(item);
                                        rowsId.push(item.id);
                                    } else {
                                        newRowsToDelId.push(item.id)
                                    }
                                }
                            });
                            if (newRowsToDelId.length) {
                                this.budgetForm = this.budgetForm.filter(item => !newRowsToDelId.includes(item.id));
                                if (!rows.length) this.selectAll = false;
                            }
                            if (rows.length) await this.deleteWithAttachedFiles(rows, rowsId, index);
                        }
                    this.afterDeleted(); // await для корректного срабатывания этой футкции
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        },

        afterDeleted() {
            // при необходимости дополнительных действий заменить в родителе
        },

        calcTotal(ids) {
            this.$set(this.header, 'value', this.calcTotalForDelete(this.budgetForm, 'total', ids));
        },

        async deleteWithAttachedFiles(items, ids, index) {
            this.isLoad = true;
            const dataToDel = {
                items,
                form: this.form.code
            }
            
            try {
                this.calcTotal(ids);
                const response = await fetch('/api-py/delete-budget-request-multiple-rows-with-files/' + JSON.stringify(this.header), {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(dataToDel)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (index !== false) {
                        this.budgetForm.splice(index, 1);
                    } else {
                        this.budgetForm = this.budgetForm.filter(item => { return !item.itemToDelete });
                        this.selectAll = false;
                    }
                    this.makeToast('success', 'Сообщение', index !== false ? 'Запись удалена' : 'Записи удалены');
                } else {
                    this.makeToast('danger', 'Предупреждение', 'Ошибка удаления данных');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка удаления данных');
            } finally {
                this.isLoad = false;
            }
        }, // удаление данных

        calcGoodsTotalBeforeDel() {
            // при необходимости заменить в родителе
            return this.budgetForm.length ? this.budgetForm.reduce((acc, sum) => sum.src_group ? acc : acc + sum.total, 0) : 0;
        },

        async deleteSeveralGoods(items, catDeleting = false) {
            const dataToDel = {
                items,
                header: { ...this.header, value: Math.ceil(this.calcGoodsTotalBeforeDel()) },
                form: this.getFormCode(),
                catDeleting,
            }
            try {
                const response = await fetch('/api-py/delete-several-goods/', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(dataToDel)
                });
                const result = await response.json();
                if (!catDeleting && (response.status === 200) && (result.result === 'success')) {
                    this.makeToast('success', 'Сообщение', 'Записи удалены');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка удаления данных');
                // return response.status;
            } finally {
                this.selectAll = false;

            }
        },

        async delete(item) {
            if (!item.length) return;
            const dataToDel = {
                item,
                header: this.header,
                form: this.getFormCode()
            }
            try {
                const response = await fetch('/api-py/delete-list-budget-request-form/', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(dataToDel)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.form.code === '01-134') this.budgetForm.forEach(itm => itm.id = -1);
                    this.makeToast('success', 'Сообщение', 'Запись удалена');
                } else {
                    this.makeToast('danger', 'Предупреждение', 'Ошибка удаления данных');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка удаления данных');
            }
        }, // удаление данных

        nameIsEmpty(item, field) {
            if (item[field] && item[field].trim() !== '') return false
            return true
        }, // возвращает true если item[field] пустое
        
        checkForDoubles(data, field) {
            if (!data || !data.length) return;
            for (const itm1 of data) {
                const trimmedName1 = itm1[field] ? itm1[field].trim().toLowerCase() : '';
                const doubles = data.filter(itm2 => {
                    const trimmedName2 = itm2[field] ? itm2[field].trim().toLowerCase() : '';
                    return itm1.id !== itm2.id
                        && trimmedName1 === trimmedName2
                })
                if (doubles.length > 0) this.$set(itm1, 'isDouble', true)
                else this.$set(itm1, 'isDouble', false)
            }
        }, // поиск дублей по текстовому полю

        isSavingForbidden(row, fieldNames, numFields = [], strFields = []) {
            let flag = false;
            if (strFields.length) {
                strFields.forEach(field => {
                    const trimmedField = row[field] ? toString(row[field]).trim() : '';
                    if (trimmedField === '') {
                        const currField = fieldNames.find(itm => itm.key === field)
                        flag = true;
                        this.makeToast('warning', 'Сообщение', `Поле "${currField.label}" не должно быть пустым. Данные не сохранены.`);
                    }
                })
            }
            if (row.isDouble) {
                flag = true;
                this.makeToast('warning', 'Сообщение', 'Обнаружены дубли. Данные не сохранены.');
            }
            if (numFields.length) {
                numFields.forEach(field => {
                    if (row[field] <= 0) {
                        const currField = fieldNames.find(itm => itm.key === field)
                        flag = true;
                        this.makeToast('warning', 'Сообщение', `Значение в поле "${currField.label}" должно быть больше 0. Данные не сохранены.`);
                    }
                })
            }
            
            return flag
        }, // возвращает true если есть дубли или одно из переданных полей <= 0

        onChangeName(newVal, data) {
            data.item.name_ru = newVal.trim();
            this.checkForDoubles(this.budgetForm, 'name_ru');
        },

        monthValidation(value, item, field, digit) {
            if (value === null || value === '' || value === '.' || !Number(value) || value < 1) {
                return;
            };
            const newVal = 12 < Number(value) ? 12 : Number(value);
            this.$set(item, field, parseFloat(parseFloat(newVal).toFixed(digit)));
        },

        yearValidation(value, item, field) {
            if (value === null || value === '' || value === '.' || !Number(value) || value < 1) {
                return;
            };
            let newVal = Number(value);
            if (this.header.year) {
                const daysInYear = this.getNumberOfDaysInYear(this.header.year);                
                newVal = daysInYear < newVal ? daysInYear : newVal;
            }
            this.$set(item, field, newVal);
        },

        getNumberOfDaysInYear(year) {
            const date = new Date(year, 11, 31); // December 31st of the given year
            return date.getUTCFullYear() % 4 === 0 && (date.getUTCFullYear() % 100 !== 0 || date.getUTCFullYear() % 400 === 0) ? 366 : 365;
        }, // возвращает кол-во дней для переданного года.

        calcTotalForDelete(items, calcField, ids = []) {
            let sum = 0;
            for (const row of items) {
                if (row.id > 0 && ids.length && !ids.includes(row.id)) {
                    sum += parseFloat(row[calcField]);
                } else if (row.id > 0 && !ids.length) {
                    sum += parseFloat(row[calcField]);
                }
            }
            return Math.ceil(sum);
        },

        setIsAllDelete(val) {
            this.budgetForm.forEach(item => this.$set(item, 'itemToDelete', val));
        },

        adjustDropdownMenuStyle() {
            const dropdownMenu = this.$refs.dropdown.$el.querySelector('.dropdown-menu');
            if (dropdownMenu) {
                dropdownMenu.style.transform = 'translate3d(-180px, 30px, 0px)';
            }
        }, // смещение дропдауна скачивания отчетев для форм 02-159-1 и -2

        openFilterByRef(refName) {
            this.$refs.budgetHeader.openFilterByRef(refName, this.header.mode);
        },
        
        setProgress(val) {
            this.progress = val;
        },

        totalCalculation(array, field) {
            let sum = 0;
            for (const row of array) {

                sum += row[field] ? parseFloat(row[field]) : 0;
            }
            return Math.ceil(sum);
        }, 

        onFieldsValidationFailed() {
            this.makeToast('danger', 'Ошибка сохранения', 'Проверьте корректность заполнения полей');
        },
        
        //--------------  для форм 02-159_1 и _2 -------------------
        async loadRegions() {
            this.listRegPr.splice(0);
            try {
                const response = await fetch('/api-py/get-user-regions-by-obl/' + this.obl +'/'+ this.$store.getters.user_uuid);
                const result = await response.json();
                const items = this.filterDistricts(result, 'code');
                for (const row of items) {
                    if (row.code.slice(-2) !== '01') continue;
                    const el = {};
                    this.$set(el, 'value', row);
                    this.$set(el, 'text', row.text);
                    this.$set(el, 'disabled', false);
                    this.listRegPr.push(el);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        }, // справочник регионов

        dasableRegions(regionsList, formData) {
            if (!regionsList || !formData) return;
            for (const reg of regionsList) {
                const isRegionExist = formData.findIndex(itm => itm.src_region && itm.region && reg.value && itm.region.code === reg.value.code) !== -1;
                reg.disabled = isRegionExist;
            }
        }, // дисайблит регионы которые уже есть 

        hasRegionProjects(item) {
            const existingRegions = {}
            this.budgetForm.forEach(itm => {
                const regionCode = itm.region ? itm.region.code : null;
                if (itm.src_region && regionCode && !existingRegions[regionCode]) {
                    existingRegions[regionCode] = true
                }
            })
            const newBudgetForm = []
            this.budgetForm.forEach(itm => {
                const regionRow = itm.src_region
                const savedRow =  itm.id > 0
             
                if (regionRow || savedRow) {
                    newBudgetForm.push(itm);
                } else {
                    const regionCode = itm.region
                    const isHasRegion = regionCode && existingRegions[regionCode]
                    if (isHasRegion) newBudgetForm.push(itm);
                }
            })
            this.budgetForm = newBudgetForm;
        },

        filterDistricts(result, field) {
            if (this.header && this.header.abp === 268 && [25, 28].includes(this.header.prg)) {
                const newResult = result.filter(itm => itm[field].slice(-4) === '0101')
                return newResult
            }
            return result
        },
        
        isProjectHasSpendings(item) {
            let isHasSpndings = false;
            const spndingsRec = this.getCurrSpendingsRec(item);
            if (spndingsRec.length > 0) {
                this.$set(item, "hasSpendings", true)
                isHasSpndings = true;
            }
            return isHasSpndings
        }, // признак доступности проекта для редактирования

        getCurrSpendingsRec(item) {
            const projectCode = item.code;
            let spndingsRec = [];
            const isBudgetFormData = this.budgetFormNoPpr && this.budgetFormNoPpr.length;
            if (isBudgetFormData && projectCode) {
                spndingsRec = this.budgetFormNoPpr.filter(itm => itm.code_project && itm.code_project === projectCode);
            }
            return spndingsRec
        },

        onShowSpndings(item) {
            this.$refs.spendingsModal.showModal(this.getCurrSpendingsRec(item))
        },

        getDataToCopy() {
            this.budgetFormToCopy.splice(0);
            if (['02-159_1', '02-159_2'].includes(this.header.form) && this.prForm.length && this.budgetForm.length) {
                const actualNextYearPrjs = this.prForm.reduce((acc, val) => {
                    const isCurrPrjActualNxtYear = val.code && val.end_date && val.end_date >= `${this.header.year + 1}-01-01`
                    if (isCurrPrjActualNxtYear) {
                        return [...acc, val.code]
                    }
                    return acc
                }, [])
                if (actualNextYearPrjs.length !== 0) {
                    this.budgetFormToCopy = this.budgetForm.filter(item => actualNextYearPrjs.includes(item.code_project))
                }
            }
        },

        async loadDataWithoutPpr(filterData) {
            if (!filterData) return;
            this.budgetFormNoPpr.splice(0);
            const data = {
                guCurr: filterData.gu,
                curAbpList: filterData.curAbpList,
            }
            try {
                const response = await fetch(`/api-py/get-budget-request-form-02-159-1-2/` + JSON.stringify(this.header),
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(data)
                });
                if (response.status === 200) {
                    const result = await response.json();
                    for (const el of result) {
                        el.name_ru = variantNameLangSupport(el, 'ru');
                        el.name_kk = variantNameLangSupport(el, 'kk');
                        el.name_en = variantNameLangSupport(el, 'en');
                    }
                    this.budgetFormNoPpr = result;
                } else {
                    this.makeToast('danger', 'Предупреждение', 'Ошибка проверки возможности дублирования данных');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка проверки возможности дублирования данных');
            }
        }, // получение данных по пробнрамме без учета ppr, year, cur_year, data_type и пр

        isDataValid(row, rowNumber) {
            if (this.isEmpty(row.region)) return this.validationErrorMessage('Регион', rowNumber);
            if (this.isEmpty(row.name_ru)) return this.validationErrorMessage('Наименование', rowNumber);
            if (this.isEmpty(row.unit)) return this.validationErrorMessage('Единица измерения', rowNumber);
            if (['001.006.008', '002.055'].includes(row.unit.code) && (row.distance === 0))  return this.validationErrorMessage('Протяженность', rowNumber);
            if (this.isEmpty(row.beg_date)) return this.validationErrorMessage('Дата начала', rowNumber);
            if ((!this.isEmpty(row.beg_date) && !this.isEmpty(row.end_date) && (row.beg_date >= row.end_date))
                || this.isEmpty(row.end_date)) return this.validationErrorMessage('Дата окончания', rowNumber);
            if (row.checkForDuplicatesReg === true || row.checkForDuplicates === true) {
                this.makeToast('warning', 'Данные не сохранены', `Обнаружены дубли в строке ${rowNumber}`);
                return false
            }
            return true
        },

        isEmpty(elem) {
            if (!elem || elem === null) return true
            if (typeof elem === 'string' && elem.trim() === '') return true
            return false
        },

        validationErrorMessage(fieldName, rowNmb) {
            this.makeToast('warning', 'Данные не сохранены', `Проверьте правильность заполнения поля "${fieldName}" в строке ${rowNmb}`);
            return false
        },

        // ---------------------------------------------------------------------
    },
    mounted() {
        this.progress = 15;
    },
    computed: {
        lang() {
            let lang = 'ru';
            if (this.$i18n.locale === 'kk') lang = 'kz';
            if (this.$i18n.locale === 'en') lang = 'en';
            return lang;
        },
        moduleCode() {
            return this.formsList.includes(this.form.code) ? '004.002.005' : '004.002.004';
        }
    }
}
